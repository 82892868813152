<template>
  <div class="px-6 pt-4 pb-9">
    <div class="flex justify-end mb-4">
      <display-limit-selector
        v-model="collabData.limit"
        class="mr-4"
        :options="limitOptions"
      />
      <base-pagination
        :model-value="collabData.page"
        :total-pages="collabData.pageCount"
        :limit="collabData.limit"
        :total-count="collabData.totalCount"
        @update:modelValue="handlePageUpdate($event)"
      />
    </div>
    <div class="flex items-center mr-4 mb-4">
      <icon-button
        title="Refresh"
        class="mr-3 w-7.5 h-7.5"
        icon="icons/arrowsClockwise.svg"
        active
        active-btn-class="bg-custom-green-1 bg-opacity-20"
        active-icon-class="text-custom-green-1"
        @click="handleRefresh()"
      />
      <base-select
        v-model="collabFilters.status"
        class="text-sm max-w-full w-40 text-left mr-3"
        :options="collabStatusOptions"
      />
      <base-button
        variant="btn-primary"
        :disabled="!endCollabBtnActive"
        class="mr-3 h-7 flex items-center"
        text="End Collaboration"
        @click="handleEndCollab()"
      />
    </div>
    <div
      v-if="collabTableLoading"
      class="mt-40 text-center"
    >
      <base-svg
        class="h-4 w-4 mr-1 text-primary-red inline-block"
        src="icons/circleSpinner.svg"
        tag="span"
      />
      Loading ...
    </div>
    <template v-else-if="collabData.tableData.data.length > 0">
      <brand-assurance-table
        id="CollaborationSubmissionsTable"
        v-model="collabData.tableData"
        are-columns-resizable
        are-columns-interchangable
        root-element-class="mb-20"
        @columnDragged="savePreferences()"
        @columnResized="savePreferences()"
      >
        <!-- inject id link in collaboration ID column -->
        <template
          v-for="(collabIdCellSlotName, collabIndex) in collabIdCellSlotNames"
          :key="collabIdCellSlotName"
          #[collabIdCellSlotName]
        >
          <span
            class="underline cursor-pointer"
            @click="openCollabDetails(collabData.tableData.data[collabIndex].collaborationId)"
          >
            {{ collabData.tableData.data[collabIndex].collaborationId }}
          </span>
        </template>
        <!-- inject formatted date in started on column -->
        <template
          v-for="(startedOnCellSlotName, collabIndex) in startedOnCellSlotNames"
          :key="startedOnCellSlotName"
          #[startedOnCellSlotName]
        >
          {{ formatDate(collabData.tableData.data[collabIndex].startedDate) }}
        </template>
        <!-- inject view collaborators button in collaborators column -->
        <template
          v-for="(assignedCollaboratorsCellSlotName, collabIndex) in assignedCollaboratorsCellSlotNames"
          :key="assignedCollaboratorsCellSlotName"
          #[assignedCollaboratorsCellSlotName]
        >
          <base-button
            variant="btn-link"
            text="View Collaborators"
            @click="viewCollaborators(collabData.tableData.data[collabIndex].collaborationId)"
          />
        </template>
      </brand-assurance-table>
      <div class="flex justify-end">
        <display-limit-selector
          v-model="collabData.limit"
          class="mr-4"
          :options="limitOptions"
        />
        <base-pagination
          :model-value="collabData.page"
          :total-pages="collabData.pageCount"
          :limit="collabData.limit"
          :total-count="collabData.totalCount"
          @update:modelValue="handlePageUpdate($event)"
        />
      </div>
    </template>
    <!-- collaborator modal -->
    <brand-assurance-collaborators-modal
      v-model="showCollaboratorsModal"
      :collab-id="selectedCollabId"
      @hide="handleCollaboratorsModal()"
    />
    <!-- confirm end collaboration modal -->
    <confirm-end-collaboration-modal
      v-model="showConfirmEndCollabModal"
      @accepted="handleEndCollabAccepted()"
      @rejected="setConfirmEndCollabModalVisibility(false)"
    />
    <!-- collaboration form modal -->
    <brand-assurance-collaboration-form-modal
      v-model="showCollaborationFormModal"
      @hide="handleCollaborationFormModal"
      @collaborationUpdated="fetchCollaborations()"
    />
  </div>
</template>

<script>
import { defineAsyncComponent, reactive, computed, watch, ref, onMounted } from 'vue';
import { useStore } from 'vuex';
import BaseSvg from '@/components/generic-components/BaseSvg.vue';
import { formatDate } from '@/helpers/util.js';
import useToastNotifications from '@/hooks/toastNotifications.js';
import useBaTable from '@/hooks/baTable.js';
import { END_COLLABORATIONS_FAILED, END_COLLABORATIONS_SUCCESS, NO_COLLABORATIONS_FOUND, ERROR, SUCCESS, WARNING, USER_NOT_MASTER_COLLABORATOR } from '@/constants/alerts';
import { ACTIVE_COLLABORTION_STATUS, END_COLLABORTION_STATUS } from '@/constants/submissions';
import useSubmissionForm from '@/components/brand-assurance-submission-form/submissionForm.js';
import useCollaborationForm from '@/components/brand-assurance-collaboration-form/collaborationForm.js';

export default {
    name: 'BACollaboration',

    components: {
        IconButton: defineAsyncComponent(() => import('@/components/IconButton.vue')),
        BaseButton: defineAsyncComponent(() => import('@/components/generic-components/BaseButton.vue')),
        BrandAssuranceTable: defineAsyncComponent(() => import('@/components/BrandAssuranceTable.vue')),
        DisplayLimitSelector: defineAsyncComponent(() => import('@/components/DisplayLimitSelector.vue')),
        BasePagination: defineAsyncComponent(() => import('@/components/generic-components/BasePagination.vue')),
        BaseSelect: defineAsyncComponent(() => import('@/components/generic-components/BaseSelect.vue')),
        BrandAssuranceCollaboratorsModal: defineAsyncComponent(() => import('@/components/BrandAssuranceCollaboratorsModal.vue')),
        ConfirmEndCollaborationModal: defineAsyncComponent(() => import('@/components/ConfirmEndCollaborationModal.vue')),
        BrandAssuranceCollaborationFormModal: defineAsyncComponent(() => import('@/components/brand-assurance-collaboration-form/BrandAssuranceCollaborationFormModal.vue')),
        BaseSvg
    },

    setup () {
        const store = useStore();
        const { generateTableSlotNamesByColumnKey } = useBaTable();
        const { notificationsStack } = useToastNotifications();
        const { fetchUserPreferences, fetchedUserPreference } = useSubmissionForm();
        const { updateCollaborationForm } = useCollaborationForm();
        const userId = computed(() => store.getters['auth/getUserId']);

        // collaboration table logic
        const fetchedCollabs = computed(() => store.getters['baCollaborations/getCollaborationsList']);
        const collabData = reactive({
            tableData: {
                columns: [
                    {
                        label: 'Collaboration ID',
                        key: 'collaborationId'
                    },
                    {
                        label: 'Collaboration Title',
                        key: 'collaborationTitle'
                    },
                    {
                        label: 'Submission ID',
                        key: 'submissionId'
                    },
                    {
                        label: 'Description',
                        key: 'description'
                    },
                    {
                        label: 'Master Collaborator',
                        key: 'collaborationMasterId'
                    },
                    {
                        label: 'Collaborators',
                        key: 'assignedCollaborators'
                    },
                    {
                        label: 'Started On',
                        key: 'startedDate'
                    },
                    {
                        label: 'Status',
                        key: 'statusName'
                    }
                ],
                data: []
            },
            page: 1,
            limit: 50,
            totalCount: fetchedCollabs.value ? fetchedCollabs.value.totalCount : 0,
            pageCount: fetchedCollabs.value ? fetchedCollabs.value.pageCount : 0
        });
        const collabIdCellSlotNames = computed(() => generateTableSlotNamesByColumnKey(collabData.tableData, 'collaborationId', 1));
        const startedOnCellSlotNames = computed(() => generateTableSlotNamesByColumnKey(collabData.tableData, 'startedDate', 1));
        const assignedCollaboratorsCellSlotNames = computed(() => generateTableSlotNamesByColumnKey(collabData.tableData, 'assignedCollaborators', 1));

        const updateCollabData = () => {
            collabData.tableData.data = fetchedCollabs.value.data
                .filter(c => c.status !== 3)
                .map(c => {
                    c.statusName = c.status === 1 ? 'Active' : 'Closed';
                    return c;
                })
                .sort((a, b) => {
                    if (!a.startedDate || !b.startedDate) return;
                    const t1 = new Date(a.startedDate);
                    const t2 = new Date(b.startedDate);
                    if (t1 < t2) return 1;
                    if (t1 > t2) return -1;
                    return 0;
                });

            collabData.pageCount = fetchedCollabs.value ? fetchedCollabs.value.pageCount : 0;
            collabData.totalCount = fetchedCollabs.value ? fetchedCollabs.value.totalCount : 0;

            console.log('/* collabData */', collabData);
            if (selectedCollabs.value.length > 0) {
                collabData.tableData.data.forEach(tableObj => {
                    tableObj.selected = false;
                });
            }
            if (collabData.tableData.data.length === 0) {
                notificationsStack.value.push({
                    type: WARNING,
                    message: NO_COLLABORATIONS_FOUND
                });
            }
        };

        const currentUser = computed(() => store.getters['auth/getUserId']);
        const savePreferences = async () => {
            try {
                let params;
                if (fetchedUserPreference?.value) {
                    fetchedUserPreference.value.style.collaboration_preference = collabData.tableData;
                    fetchedUserPreference.value.style.collaboration_preference.displayCountPreference = collabData.limit;
                    params = fetchedUserPreference.value;
                } else {
                    params = {
                        key: currentUser.value,
                        label: 'Preferences',
                        style: {
                            collaboration_preference: {
                                ...collabData.tableData,
                                displayCountPreference: collabData.limit
                            }
                        }
                    };
                }

                if (params) {
                    await store.dispatch('users/saveUserPreferences', { params });
                }
            } catch (err) {
                console.error(err);
            }
        };

        const collabTableLoading = ref(false);
        const fetchCollaborations = async () => {
            try {
                collabTableLoading.value = true;
                await fetchUserPreferences();
                await store.dispatch('baCollaborations/fetchCollaborations', {
                    params: {
                        page: collabData.page,
                        limit: collabData.limit,
                        userId: userId.value,
                        ...getCollaborationFiltersAsExpectedByServer()
                    }
                });

                updateCollabData();
                if (fetchedUserPreference?.value) {
                    if (fetchedUserPreference?.value?.style?.collaboration_preference?.columns) {
                        collabData.tableData.columns = fetchedUserPreference.value.style.collaboration_preference.columns;
                        collabData.limit = fetchedUserPreference?.value?.style?.collaboration_preference?.displayCountPreference ? fetchedUserPreference.value.style.collaboration_preference.displayCountPreference : collabData.limit;
                    }
                }
            } catch (err) {
                console.error(err);
            } finally {
                collabTableLoading.value = false;
            }
        };
        const selectedCollabId = ref('');
        const selectedCollabs = computed(() => collabData.tableData && collabData.tableData.data.length && collabData.tableData.data.filter(tableObj => tableObj.selected));
        const limitOptions = ref([
            50,
            100,
            200
        ]);
        const handlePageUpdate = async (newPage) => {
            collabData.page = newPage;
            await savePreferences();
            await fetchCollaborations();
        };
        watch(
            () => collabData.limit,
            () => {
                handlePageUpdate(1);
            }
        );
        onMounted(() => {
            fetchCollaborations();
        });

        // collaboration table action buttons logic
        const handleRefresh = () => {
            fetchCollaborations();
        };

        const isCurrentUserMasterCollaborator = computed(() =>
            selectedCollabs.value && selectedCollabs.value.length > 0 ? !selectedCollabs.value.some(collab => collab.collaborationMasterId !== userId.value) : false
        );

        const endCollabBtnActive = computed(() =>
            selectedCollabs.value &&
          selectedCollabs.value.length > 0 &&
          isCurrentUserMasterCollaborator.value &&
          selectedCollabs.value.some(collab => collab.status === 1)
        );

        const handleEndCollab = () => {
            if (isCurrentUserMasterCollaborator.value) {
                setConfirmEndCollabModalVisibility(true);
            } else {
                notificationsStack.value.push({
                    type: WARNING,
                    message: USER_NOT_MASTER_COLLABORATOR
                });
            }
        };
        const viewCollaborators = (collabId) => {
            selectedCollabId.value = collabId;
            setCollaboratorsModalVisibility(true);
        };

        const fetchedCollabDetails = computed(() => store.getters['baCollaborations/getCollaborationsDetails']);
        const openCollabDetails = async (collabId) => {
            try {
                collabTableLoading.value = true;
                await store.dispatch('baCollaborations/fetchCollaborationDetails', { collabId });
                updateCollaborationForm(fetchedCollabDetails.value);
                setCollaborationFormModalVisibility(true);
            } catch (error) {
                notificationsStack.value.push({
                    type: ERROR,
                    message: `Cannot open Collaboration ${collabId}`
                });
            } finally {
                collabTableLoading.value = false;
            }
        };

        // collab filters logic
        const collabFilters = reactive({
            status: 'Active'
        });
        const collabFiltersMap = [
            {
                label: 'Active',
                key: ACTIVE_COLLABORTION_STATUS
            },
            {
                label: 'Closed',
                key: END_COLLABORTION_STATUS
            }
        ];
        const getCollaborationFiltersAsExpectedByServer = () => {
            return {
                ...collabFilters,
                status: collabFiltersMap.find(filter => filter.label === collabFilters.status).key
            };
        };
        watch(
            () => collabFilters.status,
            () => {
                fetchCollaborations();
            }
        );

        // collab status options logic
        const collabStatusOptions = ref(collabFiltersMap.map(filter => filter.label));

        // collaborators modal logic
        const showCollaboratorsModal = ref(false);
        const setCollaboratorsModalVisibility = (visibility) => {
            showCollaboratorsModal.value = visibility;
        };
        const handleCollaboratorsModal = () => {
            selectedCollabId.value = '';
            setCollaboratorsModalVisibility(false);
        };

        // confirm end collaboration modal logic
        const showConfirmEndCollabModal = ref(false);
        const handleEndCollabAccepted = async () => {
            try {
                const promiseArr = selectedCollabs.value.map(collab => store.dispatch('baCollaborations/closeCollaboration', {
                    bodyPayload: {
                        status: 2
                    },
                    collabId: collab.collaborationId
                }));
                await Promise.all(promiseArr);
                setConfirmEndCollabModalVisibility(false);
                notificationsStack.value.push({
                    type: SUCCESS,
                    message: END_COLLABORATIONS_SUCCESS
                });
                fetchCollaborations();
            } catch (err) {
                console.error(err);
                notificationsStack.value.push({
                    type: ERROR,
                    message: END_COLLABORATIONS_FAILED
                });
            }
        };
        const setConfirmEndCollabModalVisibility = (visibility) => {
            showConfirmEndCollabModal.value = visibility;
        };

        // collaboration form modal logic
        const showCollaborationFormModal = ref(false);
        const setCollaborationFormModalVisibility = (visibility) => {
            showCollaborationFormModal.value = visibility;
        };
        const handleCollaborationFormModal = () => {
            selectedCollabId.value = '';
            setCollaborationFormModalVisibility(false);
        };

        return {
            // collaboration table
            collabData,
            collabIdCellSlotNames,
            startedOnCellSlotNames,
            assignedCollaboratorsCellSlotNames,
            limitOptions,
            handlePageUpdate,
            collabTableLoading,
            formatDate,
            // collaboration table action buttons
            handleRefresh,
            endCollabBtnActive,
            handleEndCollab,
            selectedCollabId,
            viewCollaborators,
            openCollabDetails,
            fetchCollaborations,
            // collab filters logic
            collabFilters,
            // collab status options
            collabStatusOptions,
            // collaborators modal
            showCollaboratorsModal,
            handleCollaboratorsModal,
            // confirm end collaboration modal
            showConfirmEndCollabModal,
            handleEndCollabAccepted,
            setConfirmEndCollabModalVisibility,
            // collaboration form modal
            showCollaborationFormModal,
            handleCollaborationFormModal,
            savePreferences
        };
    }
};
</script>
