import { reactive, computed } from 'vue';
import { useStore } from 'vuex';

const defaultCollaborationFormState = {
    collaborationId: '',
    collaborationMasterId: '',
    collaborationTitle: '',
    description: '',
    status: null,
    submissionId: '',
    stageKey: null,
    stepKey: null,
    closedBy: null,
    collaborators: [],
    submission: {
        submissionId: '',
        shortArticleDescription: '',
        contractNumber: null,
        styleGuideName: '',
        article: null,
        currentStage: null,
        currentStep: null,
        status: null,
        taskId: '',
        assets: null
    },
    comment: {
        description: '',
        submissionId: '',
        creatUserId: '',
        refObjTypeKey: 1,
        submissionStageKey: null,
        submissionStepKey: null,
        submissionStatusKey: null,
        createdUserTypeKey: null,
        creatUserName: '',
        taskId: ''
    }
};

let collaborationForm = reactive({
  ...defaultCollaborationFormState,
  submission: { ...defaultCollaborationFormState.submission },
  comment: { ...defaultCollaborationFormState.comment }
});

export default () => {
    const store = useStore();

    const updateCollaborationForm = (updatedForm) => {
        collaborationForm = Object.assign(collaborationForm, updatedForm);
    }

    const updateCollaborationAssets = (assets) => {
      collaborationForm.assets = assets
    }

    const updateSubmissionAssets = (assets) => {
      collaborationForm.submission.assets = assets
    }

    const resetCollaborationForm = () => {
        Object.keys(collaborationForm).forEach(key => {
          if (!(key in defaultCollaborationFormState)) {
              delete collaborationForm[key];
          } else if (['comment','submission'].includes(key)) {
            collaborationForm[key] = { ...defaultCollaborationFormState[key] }
          } else {
            collaborationForm[key] = defaultCollaborationFormState[key];
          }
        })
    }


    const isNewCollaboration = computed(() => collaborationForm.collaborationMasterId == '');
    const isCommentEmpty = computed(() => !collaborationForm.comment.description);
    const isCollaborationActive = computed(() => collaborationForm.status === 1);
    const isUserCollaborationMaster = computed(() => {
      return !collaborationForm.collaborationMasterId || store.getters['auth/getUserId'] == collaborationForm.collaborationMasterId
    })

    return {
        collaborationForm,
        isCollaborationActive,
        isNewCollaboration,
        isCommentEmpty,
        isUserCollaborationMaster,
        updateCollaborationForm,
        resetCollaborationForm,
        updateSubmissionAssets,
        updateCollaborationAssets
    };
};
